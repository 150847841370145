import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FooterComponent,
  FormFieldComponent,
  FrameComponent,
  HeaderComponent,
} from './components';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FooterComponent,
    FormFieldComponent,
    FrameComponent,
    HeaderComponent,
  ],
  exports: [
    FrameComponent,
    HeaderComponent,
    FooterComponent,
    FormFieldComponent,
  ],
})
export class SharedUiModule {}
