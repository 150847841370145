import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {
  init as initSentry,
  BrowserTracing,
  routingInstrumentation,
} from '@sentry/angular-ivy';
import { default as versionJson } from './version.json';

initSentry({
  dsn: environment.sentry.dsn,
  environment: environment.env, // develop, staging, live
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: environment.sentry.tracingOrigins,
      routingInstrumentation: routingInstrumentation,
    }),
  ],
  tracesSampler: (samplingContext) => {
    const currentRoute = samplingContext?.transactionContext?.name;
    const matchedRoute = environment.sentry.tracesSampleRate.find((routeData) =>
      currentRoute.startsWith(routeData.route)
    );

    return matchedRoute?.rate ?? environment.sentry.defaultTracesSampleRate;
  },
  release: `accounts-app@${versionJson.version}-${environment.env}`,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
