import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('@allmyhomes/accounts/feature-forgot-password').then(
        (module) => module.AccountsAppFeatureForgotPasswordModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('@allymhomes/accounts/feature-login').then(
        (module) => module.AccountsAppFeatureLoginModule
      ),
  },
  {
    path: 'select-application',
    loadChildren: () =>
      import('@allmyhomes/accounts/feature-application-selector').then(
        (module) => module.AccountsAppFeatureApplicationSelectorModule
      ),
  },
  {
    path: 'reset-password/:email/:verificationToken',
    loadChildren: () =>
      import('@allmyhomes/accounts/feature-reset-password').then(
        (module) => module.AccountsAppFeatureResetPasswordModule
      ),
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
