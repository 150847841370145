export const environment = {
  production: true,
  sentry: {
    dsn: 'https://1d7520dd45f24b088761e09a58df49db@o4505193079832576.ingest.sentry.io/4505285233475590',
    tracesSampleRate: [
      {
        route: 'GET /healthcheck',
        rate: 0.1,
      },
      {
        route: 'GET /robots.txt',
        rate: 0.1,
      },
      {
        route: 'GET /version',
        rate: 0.1,
      },
    ],
    defaultTracesSampleRate: 0.25,
    tracingOrigins: ['localhost', 'api.allmyhomes.com'],
  },
  apiUrl: 'https://api.allmyhomes.com',
  env: 'live',
};
