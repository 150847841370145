import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';

import { SharedUiModule } from '@allmyhomes/accounts/ui';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedUiModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [
    Title,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // This is intentional
      },
      deps: [TraceService],
      multi: true,
    },
    {
      provide: 'apiUrl',
      useValue: environment.apiUrl,
    },
    {
      provide: 'env',
      useValue: environment.env,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
